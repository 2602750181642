import React, { useState, useLayoutEffect } from 'react';

const useMediaQuery = mediaQuery => {
  if (typeof window !== 'undefined') {
    const [matches, setMatches] = useState(() => window.matchMedia(mediaQuery).matches);
    useLayoutEffect(() => {
      const mediaQueryList = window.matchMedia(mediaQuery);
      const listener = e => setMatches(e.matches);
      mediaQueryList.addListener(listener);
      return () => mediaQueryList.removeListener(listener);
    }, [mediaQuery]);

    return matches;
  }

  return false;
};

export default useMediaQuery;
